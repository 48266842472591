window.addEventListener('DOMContentLoaded', function(e) {
  // Original JavaScript code by Chirp Internet: chirpinternet.eu
  // Please acknowledge use of this code by including this header.

  var setupCarousel = function(elem, offset) {
	  var top = elem;
	  var fadeComplete = function() { top.appendChild(arr[offset]); };
	  var arr = top.getElementsByTagName('img');
	  for(var i=0; i < arr.length; i++) {
	    arr[i].addEventListener('animationend', fadeComplete, false);
	  }
  };

  setupCarousel(document.getElementById('top'), 1);
  setupCarousel(document.getElementById('bottom'), 0);
});
